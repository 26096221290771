import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  AuthenticatedCartScreenComponent
} from './authenticated-cart/authenticated-cart-screen/authenticated-cart-screen.component';
import {
  UnauthenticatedCartScreenComponent
} from './unauthenticated-cart/unauthenticated-cart-screen/unauthenticated-cart-screen.component';
import {CartWrapperComponent} from './cart-wrapper/cart-wrapper.component';
import {CartDetailsRoutingModule} from "./cart-details-routing.module";
import {StoreModule} from "@ngrx/store";
import {reducer} from "./core/cart.reducer";
import {EffectsModule} from "@ngrx/effects";
import {CartEffects} from "./core/cart.effects";
import {FlexLayoutModule} from "@angular/flex-layout";
import {SharedModule} from "../shared/shared.module";
import {ReactiveFormsModule} from "@angular/forms";
import {
  ShippingAddressSelectionCardComponent
} from './authenticated-cart/components/shipping-address-selection/component/shipping-address-selection-card/shipping-address-selection-card.component';
import {
  ShippingAddressSelectionScreenComponent
} from './authenticated-cart/components/shipping-address-selection/shipping-address-selection-screen/shipping-address-selection-screen.component';
import {
  CardSelectionScreenComponent
} from './authenticated-cart/components/card-selection/card-selection-screen/card-selection-screen.component';
import {
  UnauthenticatedCartEditableRowComponent
} from './unauthenticated-cart/components/unauthenticated-cart-editable-row/unauthenticated-cart-editable-row.component';
import {
  UnauthenticatedCartNonEditableRowComponent
} from './unauthenticated-cart/components/unauthenticated-cart-non-editable-row/unauthenticated-cart-non-editable-row.component';
import {
  AuthenticatedCartEditableRowComponent
} from './authenticated-cart/components/authenticated-cart-editable-row/authenticated-cart-editable-row.component';
import {OrderSuccessDialogComponent} from "./common/components/order-success-dialog/order-success-dialog.component";
import { OrderConfirmFailedDialogComponent } from './common/components/order-confirm-failed-dialog/order-confirm-failed-dialog.component';


@NgModule({
  declarations: [
    AuthenticatedCartScreenComponent,
    UnauthenticatedCartScreenComponent,
    CartWrapperComponent,
    ShippingAddressSelectionCardComponent,
    ShippingAddressSelectionScreenComponent,
    CardSelectionScreenComponent,
    UnauthenticatedCartEditableRowComponent,
    UnauthenticatedCartNonEditableRowComponent,
    AuthenticatedCartEditableRowComponent,
    OrderSuccessDialogComponent,
    OrderConfirmFailedDialogComponent,
  ],
  exports: [
    ShippingAddressSelectionCardComponent
  ],
  imports: [
    CommonModule,
    CartDetailsRoutingModule,
    StoreModule.forFeature('cartDetails', reducer),
    EffectsModule.forFeature(
      [CartEffects]
    ),
    FlexLayoutModule,
    SharedModule,
    ReactiveFormsModule,
  ]
})
export class CartDetailsModule {
}
