import * as CartActions from './cart.actions';
import {createReducer, on} from '@ngrx/store';
import {GuestPurchaseFormModel} from "../../models/guest-purchase-form.model";
import {CartDataModel} from "../../models/cartData.model";
import * as TeaDetailsAction from "../../tea-details/core/tea-details.actions";

// State for this feature (User)
export interface CartDetailsState {
  paymentCards: any[];
  addressList: any[];
  cartData: CartDataModel;
  enableGuestPurchaseForm: boolean;
  guestCart: {
    countryId: string,
    items: any[],
  };
  guestPurchaseForm: GuestPurchaseFormModel;
  cartCount: number;
}

const initialState: CartDetailsState = {
  cartCount: 0,
  addressList: [],
  paymentCards: [],
  cartData: {
    loading: false,
    success: null,
    currency: '',
    amount: 0,
    tax: 0,
    total: 0,
    items: [],
    address: {},
    conversionFactor: 0,
    currencyCode: '',
    currencySymbol: '',
    discountCouponDetails: null,
    discountCouponId: null,
    isDiscountApplied: null,
  },
  enableGuestPurchaseForm: false,
  guestCart: {
    countryId: '',
    items: []
  },
  guestPurchaseForm: {
    fullName: '',
    email: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    phone: ''
  }
};

export const reducer = createReducer(
  initialState,

  // CLEAR STATE Retain Cart
  on(CartActions.ClearStateRetainCart, (state) => ({
    ...initialState,
    guestCart: state.guestCart,
    cartCount: state.cartCount,
    cartData: state.cartData
  })),

  // CLEAR STATE
  on(CartActions.ClearState, (state) => initialState),

  // CALCULATE CART
  on(CartActions.CalculateCartRequest, (state) => ({
    ...state,
    cartData: {
      ...state.cartData,
      success: null,
      loading: !state.cartData.items.length
    }
  })),
  on(CartActions.CalculateCartSuccess, (state, props) => ({
    ...state,
    cartData: {
      ...props.cartData,
      success: true,
      loading: false
    }
  })),
  on(CartActions.CalculateCartFailure, (state) => ({
    ...state,
    cartData: {
      ...state.cartData,
      success: false,
      loading: false
    }
  })),

  // ADD TO ITEMS
  on(CartActions.AddToItems, (state, props) => (
    {
      ...state,
      guestCart: {
        ...state.guestCart,
        ...(props.countryId !== undefined ? {countryId: props.countryId} : {}),
        items: props.items
      },
      cartCount: props.items.length
    }
  )),

  // ENABLE GUEST PURCHASE FORM
  on(CartActions.EnableGuestPurchaseForm, (state, props) => ({
    ...state,
    enableGuestPurchaseForm: props.enableGuestPurchaseForm
  })),

  // RESET CART
  on(CartActions.ResetCart, (state) => ({
    ...state,
    cartData: initialState.cartData
  })),

  // SAVE GUEST PURCHASE FORM
  on(CartActions.SaveGuestPurchaseForm, (state, props) => ({
    ...state,
    guestPurchaseForm: props.payload
  })),

  // FETCH CART
  on(CartActions.FetchCartRequest, (state) => ({
    ...state,
    cartData: {
      ...initialState.cartData,
      success: null,
      loading: true
    }
  })),
  on(CartActions.FetchCartSuccess, (state, props) => ({
    ...state,
    cartData: {
      ...props.cartData,
      success: true,
      loading: false
    }
  })),
  on(CartActions.FetchCartFailure, (state) => ({
    ...state,
    cartData: {
      ...initialState.cartData,
      success: false,
      loading: false
    }
  })),

  // ADD TO CART
  on(CartActions.AddToCartRequest, (state) => state),
  on(CartActions.AddToCartSuccess, (state, props) => ({
    ...state,
    cartData: {
      ...state.cartData,
      ...props.cartData
    },
  })),
  on(CartActions.AddToCartFailure, (state) => state),

  // FETCH CART COUNT
  on(CartActions.FetchCartCountRequest, (state) => state),
  on(CartActions.FetchCartCountSuccess, (state, props) => ({
    ...state,
    cartCount: props.cartCount
  })),
  on(CartActions.FetchCartCountFailure, (state) => state),

  // QUANTITY INCREMENT
  on(CartActions.QuantityIncrementRequest, (state) => state),
  on(CartActions.QuantityIncrementSuccess, (state, props) => ({
    ...state,
    cartData: {
      ...state.cartData,
      ...props.cartData
    }
  })),
  on(CartActions.QuantityIncrementFailure, (state) => state),

  // QUANTITY DECREMENT
  on(CartActions.QuantityDecrementRequest, (state) => state),
  on(CartActions.QuantityDecrementSuccess, (state, props) => ({
    ...state,
    cartData: {
      ...state.cartData,
      ...props.cartData
    }
  })),
  on(CartActions.QuantityDecrementFailure, (state) => state),


  // REMOVE ITEM
  on(CartActions.RemoveItemRequest, (state) => state),
  on(CartActions.RemoveItemSuccess, (state, props) => ({
    ...state,
    cartData: props.cartData
  })),
  on(CartActions.RemoveItemFailure, (state) => state),

  // FETCH ADDRESS LIST
  on(CartActions.FetchAddressListRequest, (state) => state),
  on(CartActions.FetchAddressListSuccess, (state, props) => ({
    ...state,
    addressList: props.addressList,
  })),
  on(CartActions.FetchAddressListFailure, (state) => state),

  // FETCH CARDS LIST
  on(CartActions.UserPaymentCardsRequest, (state) => state),
  on(CartActions.UserPaymentCardsSuccess, (state, props) => ({
    ...state,
    paymentCards: props.cardsList,
  })),
  on(CartActions.UserPaymentCardsFailure, (state) => state),

  // SET SHIPPING ADDRESS
  on(CartActions.SetShippingAddressRequest, (state) => state),
  on(CartActions.SetShippingAddressSuccess, (state, props) => ({
    ...state,
    cartData: {
      ...state.cartData,
      ...props.cartData
    }
  })),
  on(CartActions.SetShippingAddressFailure, (state) => state),

  // UPDATE PAYMENT OPTION
  on(CartActions.UpdatePaymentOptionRequest, (state) => state),
  on(CartActions.UpdatePaymentOptionSuccess, (state, props) => ({
    ...state,
    cartData: {
      ...state.cartData,
      ...props.cartData
    }
  })),
  on(CartActions.UpdatePaymentOptionFailure, (state) => state),

  // CLEAR CART DATA
  on(CartActions.ClearCartData, (state, props) => ({
    ...state,
    cartData: initialState.cartData
  })),

  // CLEAR CART COUNT
  on(CartActions.ClearCartCount, (state, props) => ({
    ...state,
    cartCount: initialState.cartCount
  })),

  // PLACE ORDER
  on(CartActions.PlaceOrderRequest, (state) => state),
  on(CartActions.PlaceOrderSuccess, (state) => state),
  on(CartActions.PlaceOrderFailure, (state) => state),

  // Cart SET
  on(CartActions.CartSetRequest, (state) => state),
  on(CartActions.CartSetSuccess, (state, props) => ({
    ...state,
    cartData: {
      ...state.cartData,
      ...props.cartData,
    },
    cartCount: props?.cartData?.items?.length || state.cartCount
  })),
  on(CartActions.CartSetFailure, (state) => ({
    ...state,
    cartData: initialState.cartData
  })),

  // Reset Success State
  on(TeaDetailsAction.ResetSuccessState, (state) => ({
    ...state,
    success: null
  })),
);
