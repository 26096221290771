
import {Component, OnDestroy, OnInit} from '@angular/core';
import {Translations} from "../../../../translations/cart-details/confirm-payment-failuare-dialog.translations";
import {select, Store} from "@ngrx/store";
import * as fromRoot from "../../../../../state/app.state";
import {Subscription} from "rxjs";
import {selectedLanguage} from "../../../../shared/core/shared.selectors";
import {CommonService} from "../../../../shared/services/common.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-order-confirm-failed-dialog',
  templateUrl: './order-confirm-failed-dialog.component.html',
  styleUrls: ['./order-confirm-failed-dialog.component.css']
})
export class OrderConfirmFailedDialogComponent implements OnInit {
language: number = 1;
  translations: any = Translations;

  // Set up language specific translations
  languageSubscription: Subscription = this.store.pipe(select(selectedLanguage)).subscribe(selectedLanguage => {
    this.language = selectedLanguage;
  });

  constructor(
    private store: Store<fromRoot.State>,
    private commonService: CommonService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.translations = Translations[this.language];
  }

  backToHome() {
    this.commonService.closeDialog();
    this.router.navigate(['/'])
  }

  ngOnDestroy() {
    this.languageSubscription?.unsubscribe();
  }

}
