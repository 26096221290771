import {Inject, Injectable} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {select, Store} from "@ngrx/store";
import * as fromRoot from "../../../state/app.state";
import {catchError, map, Subject, takeUntil, throwError} from "rxjs";
import {HttpClient, HttpResponse} from "@angular/common/http";
import * as SharedActions from '../core/shared.actions';
import {FetchCountriesListRequest} from '../core/shared.actions';
import {
  ClearState as ClearAuthenticationState,
  LogoutRequest,
  SetProfileEmail
} from '../../authentication/core/authentication.actions';
import {
  ClearState as ClearCartDetailsState,
  ClearStateRetainCart as ClearCartStateRetainCart,
  FetchCartCountRequest
} from '../../cart-details/core/cart.actions';
import {ClearState as ClearLandingPageState} from '../../landing-page/core/landing-page.actions';
import {ClearState as ClearPackageDetailsState} from '../../package-details/core/package-details.actions';
import {
  ClearState as ClearTeaDetailsState,
  ClearState as ClearUserDetailsState
} from '../../tea-details/core/tea-details.actions';
import {ClearState as ClearResetPasswordState} from '../../reset-password/core/reset-password.actions';
import {ClearState as ClearDeleteAccountState} from '../../delete-account/core/delete-account.actions';
import {DesktopBasicDialogComponent} from "../components/dialog/desktop-basic-dialog/desktop-basic-dialog.component";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import * as SharedSelectors from '../core/shared.selectors';
import {isMobileView} from '../core/shared.selectors';
import {DOCUMENT} from "@angular/common";
import {AbstractControl, FormGroup, ValidationErrors} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";
import {units} from '../constants/common.constants';
import * as $ from "jquery";
import { environment } from 'src/environments/environment';

declare var Stripe: any;
// Define a type for the payment confirmation result
export interface PaymentResult {
  status: 'succeeded' | 'failed' | 'unknown' | 'requires_action';
  paymentIntent?: any;  // paymentIntent will only exist if payment succeeded
  error?: string;        // error message in case of failure
}
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  openMobileDialog: Subject<any> = new Subject();
  closeMobileDialog: Subject<void> = new Subject();
  enableGuestPurchaseForm: Subject<boolean> = new Subject();
  isLoadingScreenOpen: Subject<boolean> = new Subject();
  triggerAuthenticationStatusCheck: Subject<void> = new Subject();
  zipCodeRegex = new RegExp('^(([0-9])+([-]?))*([0-9])$');
  emailRegex = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$';
  // Used for copyToClipboard(value)
  private dom: Document;
  private language: number | undefined;
  private country: string | undefined;
  private destroyed = new Subject<void>();
  previousUrl: string;
  private unicodeHiragana = '[\\u3041-\\u3096\\u309D-\\u309F]|\\uD82C\\uDC01|\\uD83C\\uDE00';
  private unicodeKatakana = '[\\u30A1-\\u30FA\\u30FD-\\u30FF\\u31F0-\\u31FF\\u32D0-\\u32FE\\u3300-\\u3357\\uFF66-\\uFF6F\\uFF71-\\uFF9D]|\\uD82C\\uDC00';
  private unicodeHan = '[\\u2E80-\\u2E99\\u2E9B-\\u2EF3\\u2F00-\\u2FD5\\u3005\\u3007\\u3021-\\u3029\\u3038-\\u303B\\u3400-\\u4DB5\\u4E00-\\u9FD5\\uF900-\\uFA6D\\uFA70-\\uFAD9]|[\\uD840-\\uD868\\uD86A-\\uD86C\\uD86F-\\uD872][\\uDC00-\\uDFFF]|\\uD869[\\uDC00-\\uDED6\\uDF00-\\uDFFF]|\\uD86D[\\uDC00-\\uDF34\\uDF40-\\uDFFF]|\\uD86E[\\uDC00-\\uDC1D\\uDC20-\\uDFFF]|\\uD873[\\uDC00-\\uDEA1]|\\uD87E[\\uDC00-\\uDE1D]';
  alphabetsRegex = `^[^-\\s]([a-zA-Z]|${this.unicodeHiragana}|${this.unicodeKatakana}|${this.unicodeHan}| )+$`
  alphabetsNumberRegex = `^[^-\\s]([0-9a-zA-Z]|${this.unicodeHiragana}|${this.unicodeKatakana}|${this.unicodeHan}| )+$`
  private stripe: any;
  constructor(
    @Inject(DOCUMENT) dom: Document,
    private store: Store<fromRoot.State>,
    private router: Router,
    private http: HttpClient,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private activatedRoute: ActivatedRoute
  ) {
    this.dom = dom;
    this.store
      .pipe(select(SharedSelectors.selectedLanguage))
      .pipe(takeUntil(this.destroyed))
      .subscribe((selectedLanguage) => {
        this.language = selectedLanguage;
        this.stripe = Stripe(environment.stripePublishableKey, {locale: this.language === 2 ? `ja` : `en` }); // Initialize Stripe with publishable ke
      });
    this.store
      .pipe(select(SharedSelectors.selectedCountry))
      .pipe(takeUntil(this.destroyed))
      .subscribe((selectedCountry) => {
        this.country = selectedCountry;
      });

  }

    // Method to confirm payment
    confirmPayment(clientSecret: string): Promise<PaymentResult> {
      return this.stripe.confirmCardPayment(clientSecret).then((result: any) => {
        if (result.error) {
          // If there is an error during payment confirmation
          return { status: 'failed', error: result.error.message };
        } else if (result.paymentIntent && result.paymentIntent.status === 'succeeded') {
          // If payment is successful
          return { status: 'succeeded', paymentIntent: result.paymentIntent };
        }
        // In case of an unknown status
        return { status: 'unknown' };
      });
    }


  setToLocalStorage(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  removeFromLocalStorage(key: string) {
    localStorage.removeItem(key);
  }

  getFromLocalStorage(key: string) {
    return localStorage.getItem(key);
  }

  setToSessionStorage(key: string, value: string) {
    sessionStorage.setItem(key, value);
  }

  getFromSessionStorage(key: string) {
    return sessionStorage.getItem(key);
  }

  removeFromSessionStorage(key: string) {
    sessionStorage.removeItem(key);
  }

  setAuthenticationToken(token: string) {
    this.setToLocalStorage('shop.teplotea.com/token', token);
  }

  getAuthenticationToken() {
    return this.getFromLocalStorage('shop.teplotea.com/token');
  }

  removeAuthenticationToken() {
    return this.removeFromLocalStorage('shop.teplotea.com/token');
  }

  isAuthenticated() {
    return !!this.getAuthenticationToken();
  }

  callAPI(type: string, url: string, payload?: any, options: any = {}) {
    // @ts-ignore
    let apiCall = this.http[type]<any>(url, payload, {
      observe: 'response',
      ...options,
    });

    if (type === 'delete') {
      apiCall = this.http[type]<any>(url, {
        observe: 'response',
        body: payload || {},
        ...options,
      });
    }

    return apiCall.pipe(
      map((response: any) => {
        if (response instanceof HttpResponse) {
          return response.body;
        }

        return response;
      }),
      catchError((error) => throwError(error))
    );
  }

  startLoading() {
    this.store.dispatch(SharedActions.StartLoading());
  }

  stopLoading() {
    this.store.dispatch(SharedActions.StopLoading());
  }

  openDesktopDialog(config = {}) {
    config = {
      ...config,
      backdropClass: 'desktop-dialog-backdrop'
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'dialog';
    this.dialog.open(DesktopBasicDialogComponent, {...dialogConfig, ...config});
  }

  closeDesktopDialog() {
    this.dialog.closeAll();
  }

  OSName() {
    let OSName = '';
    if (navigator.userAgent.indexOf('Win') !== -1) OSName = 'Windows';
    if (navigator.userAgent.indexOf('Mac') !== -1) OSName = 'Macintosh';
    if (navigator.userAgent.indexOf('Linux') !== -1) OSName = 'Linux';
    if (navigator.userAgent.indexOf('Android') !== -1) OSName = 'Android';
    if (navigator.userAgent.indexOf('like Mac') !== -1) OSName = 'iOS';
    return OSName;
  }

  browserDetails() {
    let browserAgent = navigator.userAgent;
    const objappVersion = navigator.appVersion;
    browserAgent = navigator.userAgent;
    let browserName = navigator.appName;
    let browserVersion = '' + parseFloat(navigator.appVersion);
    let browserMajorVersion = parseInt(navigator.appVersion, 10);
    let Offset, OffsetVersion, ix;

    // For Chrome
    if ((OffsetVersion = browserAgent.indexOf('Chrome')) !== -1) {
      browserName = 'Chrome';
      browserVersion = browserAgent.substring(OffsetVersion + 7);
    }

    // For Microsoft internet explorer
    else if ((OffsetVersion = browserAgent.indexOf('MSIE')) !== -1) {
      browserName = 'Microsoft Internet Explorer';
      browserVersion = browserAgent.substring(OffsetVersion + 5);
    }

    // For Firefox
    else if ((OffsetVersion = browserAgent.indexOf('Firefox')) !== -1) {
      browserName = 'Firefox';
    }

    // For Safari
    else if ((OffsetVersion = browserAgent.indexOf('Safari')) !== -1) {
      browserName = 'Safari';
      browserVersion = browserAgent.substring(OffsetVersion + 7);
      if ((OffsetVersion = browserAgent.indexOf('Version')) !== -1)
        browserVersion = browserAgent.substring(OffsetVersion + 8);
    }

    // For other browser "name/version" is at the end of userAgent
    else if ((Offset = browserAgent.lastIndexOf(' ') + 1) <
      (OffsetVersion = browserAgent.lastIndexOf('/'))) {
      browserName = browserAgent.substring(Offset, OffsetVersion);
      browserVersion = browserAgent.substring(OffsetVersion + 1);
      if (browserName.toLowerCase() === browserName.toUpperCase()) {
        browserName = navigator.appName;
      }
    }

    // Trimming the fullVersion string at
    // semicolon/space if present
    if ((ix = browserVersion.indexOf(';')) !== -1)
      browserVersion = browserVersion.substring(0, ix);
    if ((ix = browserVersion.indexOf(' ')) !== -1)
      browserVersion = browserVersion.substring(0, ix);


    browserMajorVersion = parseInt('' + browserVersion, 10);
    if (isNaN(browserMajorVersion)) {
      browserVersion = '' + parseFloat(navigator.appVersion);
      browserMajorVersion = parseInt(navigator.appVersion, 10);
    }
    return {browserName, browserVersion}

  }

  checkFormValidation(form: FormGroup, errorMessageMap: any, currentField?: string) {

    const errorMessages = {};
    const formControls = form.controls || null;

    if (currentField) {
      const errors = form.controls[currentField].errors;
      const firstErrorType = errors ? Object.keys(errors)[0] : '';
      // @ts-ignore
      errorMessages[currentField] = errorMessageMap[currentField][firstErrorType];
      return errorMessages;
    }

    for (const eachControlName in formControls) {
      const isControlValid = form.controls[eachControlName].valid;
      if (!isControlValid) {
        const errors = form.controls[eachControlName].errors;
        const firstErrorType = errors ? Object.keys(errors)[0] : null;
        if (firstErrorType) {
          form.controls[eachControlName].markAsTouched();
          // @ts-ignore
          errorMessages[eachControlName] = errorMessageMap[eachControlName][firstErrorType];
        }
      }
    }
    return errorMessages;
  }

  notification(message, type?, actionText?, duration = 2000) {

    this.snackBar.open(message, actionText, {
      duration,
      verticalPosition: 'bottom', // 'top' | 'bottom'
      horizontalPosition: 'end', // 'start' | 'center' | 'end' | 'left' | 'right'
      panelClass: [type],
    });
  }

  openInNewTab(url) {
    window.open(url, '_blank');
  }

  openInCurrentTab(url) {
    window.open(url, '_self');
  }

  getLanguage() {
    return this.language;
  }

  setLanguage(language: number) {
    this.store.pipe(select(SharedSelectors.selectedLanguage)).subscribe(data => {
      const clientAreaAnchor = $("#client_area");

      if (data !== Number(language)) {
        this.clearAppState({retainCart: true, retainAuthentication: true});

        clientAreaAnchor.animate({scrollTop: clientAreaAnchor.offset()?.top - 100}, 500);

        let queryParams: any = {};

        this.activatedRoute.queryParams.subscribe((params) => {
          queryParams = {...params};
          delete queryParams.language;
        }).unsubscribe();

        const fragment = this.activatedRoute.snapshot.fragment;

        const route = new URL(window.location.href).pathname;
        this.router.navigateByUrl('/dummy-url', {skipLocationChange: true}).then(() => {
          this.store.dispatch(SharedActions.SetLanguage({selectedLanguage: Number(language)}));
          this.router.navigate([`${route}`], {
            queryParams,
            ...(fragment ? {fragment} : {})
          }).then(() => {
            if (this.isAuthenticated() && (
              !this.router.url.includes('delete-account') &&
              !this.router.url.includes('resetpassword')
            )) {
              this.store.dispatch(FetchCartCountRequest({}));
            }
            this.store.dispatch(FetchCountriesListRequest());
          });
        });
      }
    }).unsubscribe();
  }

  getCountry() {
    return this.country;
  }

  clearAppState(options: any = {}) {
    const {retainAuthentication, retainCart} = options;
    this.closeDialog();
    this.store.dispatch(SharedActions.ClearState());
    if (!retainAuthentication) {
      this.store.dispatch(ClearAuthenticationState());
    }
    if (retainCart) {
      this.store.dispatch(ClearCartStateRetainCart());
    } else {
      this.store.dispatch(ClearCartDetailsState());
    }
    this.store.dispatch(ClearLandingPageState());
    this.store.dispatch(ClearPackageDetailsState());
    this.store.dispatch(ClearTeaDetailsState());
    this.store.dispatch(ClearUserDetailsState());
    this.store.dispatch(ClearResetPasswordState());
    this.store.dispatch(ClearDeleteAccountState());
  }

  clearStorage() {
    this.removeAuthenticationToken();

    const appLocalStorageKeys = [
      'shared',
      'authentication',
      'cartDetails',
      'landingPage',
      'packageDetails',
      'teaDetails',
      'userDetails',
      'resetPassword',
      'deleteAccount'
    ];

    appLocalStorageKeys.forEach((key, index) => {
      this.removeFromLocalStorage(key);
    });
  }

  logout() {
    this.store.dispatch(LogoutRequest());
  }

  openDialog(params) {
    this.store.pipe(select(isMobileView)).subscribe((isMobileView) => {
      if (isMobileView) {
        this.openMobileDialog.next(params);
      } else {
        this.openDesktopDialog(params);
      }
    });
  }

  closeDialog() {
    // Close mobile dialog.
    this.closeMobileDialog.next();

    // Close desktop dialog
    this.closeDesktopDialog();
  }

  validatePassword(control: AbstractControl): ValidationErrors | null {
    const password = control.value;
    let errors = {};

    const containsLowercase = new RegExp('[a-z]');
    if (!containsLowercase.test(password)) {
      errors = {
        ...errors,
        lowerCaseCheckFailed: true
      }
      return errors;
    }

    const containsUppercase = new RegExp('[A-Z]');
    if (!containsUppercase.test(password)) {
      errors = {
        ...errors,
        upperCaseCheckFailed: true
      }
      return errors;
    }

    const containsNumber = new RegExp('[0-9]');
    if (!containsNumber.test(password)) {
      errors = {
        ...errors,
        numberCheckFailed: true
      }
      return errors;
    }

    const containsSpecialChar = new RegExp('[!@#$%^&*()\\\\/\\[\\]\-_=+{}|?>.<,:;~`\'÷×₹€¥"]');
    if (!containsSpecialChar.test(password)) {
      errors = {
        ...errors,
        specialCharCheckFailed: true
      }
      return errors;
    }

    return null;
  }

  formatByUnitSystem(value, type, unitSystem) {
    if (unitSystem === 1) {
      return value;
    }

    let formattedValue;
    switch (type) {
      case 'temperature':
        formattedValue = (value * 9 / 5) + 32;
        break;
      case 'volume':
        formattedValue = value / 29.574;
        break;
      case 'mass':
        formattedValue = value / 28.35;
        break;
      case 'time':
        formattedValue = value;
        break;
    }

    return Math.round(formattedValue * 100) / 100

  }

  secondsToHms(d, language, unitSystem) {
    d = Number(d);
    language = Number(language);
    unitSystem = Number(unitSystem);
    const h = Math.floor(d / 3600);
    const m = Math.floor(d % 3600 / 60);
    const s = Math.floor(d % 3600 % 60);
    const hDisplay = h > 0 ? (h == 1 ? units[unitSystem][language].hour : units[unitSystem][language].hours) : '';
    const mDisplay = m > 0 ? (m == 1 ? units[unitSystem][language].minute : units[unitSystem][language].minutes) : '';
    const sDisplay = s > 0 ? (s == 1 ? units[unitSystem][language].second : units[unitSystem][language].seconds) : '';

    if (h && m && s) {
      return `${h}${hDisplay}, $ {m}${mDisplay}, $ {s}${sDisplay}`
    }

    if (m && s) {
      return `${m} ${mDisplay}, ${s} ${sDisplay}`
    }

    if (h) {
      return `${h} ${hDisplay}`
    }

    if (m) {
      return `${m} ${mDisplay}`
    }

    if (s) {
      return `${s} ${sDisplay}`
    }

    return hDisplay + mDisplay + sDisplay;
  }

  setFocus(form, errorMessages, focus) {
    const formFieldsList = Object.keys(form.controls);

    for (const eachFormField of formFieldsList) {
      if (errorMessages[eachFormField] !== undefined) {
        focus[eachFormField] = true;
        return;
      } else {
        focus[eachFormField] = false;
      }
    }
  }

  handleQueryParamsChange(params) {
    const {data, token, language} = params;

    if (data) {
      const parsedData = JSON.parse(atob(data));
      // If email present in queryParams
      if (parsedData && parsedData.email) {
        this.store.dispatch(SetProfileEmail({email: parsedData.email}))
      }
    }

    // If token present in queryParams
    if (token) {
      this.setToLocalStorage('token', token);
    }

    // If language present in queryParams
    if (language) {
      this.setLanguage(Number(language));
    }
  }

  headerRemOffsetCalculator() {
    const currentScreenWidth = window.innerWidth;
    let remBase = 20;

    if (currentScreenWidth < 1439) {
      remBase = 8;
    } else if (currentScreenWidth < 1919) {
      remBase = 10;
    } else if (currentScreenWidth < 2559) {
      remBase = 12;
    } else if (currentScreenWidth < 3439) {
      remBase = 16;
    }

    return 14 * remBase;
  }
}
