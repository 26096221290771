interface ConfirmPaymentFailuareDialogTranslations {
    backToHomeButtonLabel: string,
    failedMessage: string,
  }

  const en: ConfirmPaymentFailuareDialogTranslations = {
    backToHomeButtonLabel: 'Back to Home',
    failedMessage: 'Your Order has been Failed.',
  }

  const ja: ConfirmPaymentFailuareDialogTranslations = {
    backToHomeButtonLabel: 'ホームに戻る',
    failedMessage: '注文が失敗しました',
  }

  export const Translations = {
    1: en,
    2: ja
}
