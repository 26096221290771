import {environment} from '../../../../environments/environment';

const baseURL = environment.apiURL;

const v1Base = `${baseURL}/api/v1`;

const v2Base = `${baseURL}/api/v2`;

export const v1URL = {
  filter: {
    countries: {
      fetch: {
        url: `${v1Base}/filter/countries/fetch`,
        method: 'post',
      },
    },
    gender: {
      fetch: {
        url: `${v1Base}/filter/gender/fetch`,
        method: 'post',
      },
    },
    catalog: {
      fetch: {
        url: `${v1Base}/filter/catalog/fetch`,
        method: 'post',
      },
    },
    company: {
      fetch: {
        url: `${v1Base}/filter/company/fetch`,
        method: 'post',
      },
    },
  },

  user: {
    login: {
      url: `${v1Base}/user/login`,
      method: 'post',
    },

    logout: {
      url: `${v1Base}/user/logout`,
      method: 'post',
    },

    signUp: {
      url: `${v1Base}/user/signup`,
      method: 'post',
    },

    delete: {
      url: `${v1Base}/user/delete/confirm`,
      method: 'post',
    },

    profile: {
      update: {
        url: `${v1Base}/user/profile/update`,
        method: 'post',
      },
      fetch: {
        url: `${v1Base}/user/profile/fetch`,
        method: 'post',
      },
    },

    password: {
      reset: {
        request: {
          url: `${v1Base}/user/password/reset/request`,
          method: 'post',
        },
        set: {
          url: `${v1Base}/user/password/reset/set`,
          method: 'post',
        },
      },
    },

    cards: {
      url: `${v1Base}/user/cards`,
      method: `post`,
    },
    updateCard: `${v1Base}/user/card`,
    orders: `${v1Base}/user/orders`,
    removeAddress: `${v1Base}/user/address`,
    removeCard: `${v1Base}/user/card`,
    setOrderCard: `${v1Base}/user/order`,
  },

  order: {
    place: {
      url: `${v2Base}/order/place`,
      method: 'post',
    },
    fetch: {
      url: `${v1Base}/order/fetch`,
      method: 'post',
    },
  },

  subscription: {
    fetch: {
      url: `${v1Base}/subscription/fetch`,
      method: 'post',
    },
    cancel: {
      url: `${v1Base}/subscription/cancel`,
      method: 'post',
    },
  },

  address: {
    add: {
      url: `${v1Base}/address/add`,
      method: `post`,
    },
    update: {
      url: `${v1Base}/address/update`,
      method: `post`,
    },
    remove: {
      url: `${v1Base}/address/remove`,
      method: `post`,
    },
    fetch: {
      url: `${v1Base}/address/fetch`,
      method: `post`,
    },
  },

  paymentOption: {
    add: {
      url: `${v1Base}/paymentOption/add`,
      method: `post`,
    },
    remove: {
      url: `${v1Base}/paymentOption/remove`,
      method: `post`,
    },
    fetch: {
      url: `${v1Base}/paymentOption/fetch`,
      method: `post`,
    },
    makeDefault: {
      url: `${v1Base}/paymentOption/makeDefault`,
      method: `post`,
    },
  },

  product: {
    landingPage: {
      fetch: {
        url: `${v1Base}/product/landingPage/fetch`,
        method: 'post',
      },
    },
    fetch: {
      url: `${v1Base}/product/fetch`,
      method: 'post',
    }
  },

  cart: {
    item: {
      add: {
        url: `${v1Base}/cart/item/add`,
        method: 'post',
      },
      remove: {
        url: `${v1Base}/cart/item/remove`,
        method: 'post',
      },
      quantity: {
        increment: {
          url: `${v1Base}/cart/item/quantity/increment`,
          method: 'post',
        },
        decrement: {
          url: `${v1Base}/cart/item/quantity/decrement`,
          method: 'post',
        },
        count: {
          url: `${v1Base}/cart/item/quantity/count`,
          method: 'post',
        },
      },
    },
    fetch: {
      url: `${v1Base}/cart/fetch`,
      method: 'post',
    },
    update: {
      url: `${v1Base}/cart/update`,
      method: 'post',
    },
    set: {
      url: `${v1Base}/cart/set`,
      method: 'post',
    },
  },

  brew: {
    coldCarafe: {
      notify: {
        url: `${v1Base}/brew/coldCarafe/notify`,
        method: 'post',
      },
    },
  },

  guest: {
    calculateCart: {
      url: `${v1Base}/guest/calculateCart`,
      method: 'post',
    },

    placeOrder: {
      url: `${v2Base}/guest/placeOrder`,
      method: 'post',
    },
  },

  productReview: {
    url: `${v1Base}/product/review/fetch`,
    method: 'post',
  },

  invoice: {
    fetch: {
      url: `${v1Base}/invoice/fetch`,
      method: 'post',
    },
  },
};
