import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PageNotFoundComponent} from "./modules/shared/pages/page-not-found/page-not-found.component";
import {AuthGuardService} from "./modules/shared/services/auth-guard.service";
import {MaintenancePageComponent} from "./modules/shared/pages/maintenance-page/maintenance-page.component";
import {MaintenanceGuardService} from "./modules/shared/services/maintenance-guard.service";
import {ResetPasswordGuardService} from "./modules/shared/services/reset-password-guard.service";


const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => {
      return import('./modules/landing-page/landing-page.module').then(m => m.LandingPageModule)
    }
  },
  {
    path: 'tea',
    loadChildren: () => {
      return import('./modules/tea-details/tea-details.module').then(m => m.TeaDetailsModule)
    }
  },
  {
    path: 'package',
    loadChildren: () => {
      return import('./modules/package-details/package-details.module').then(m => m.PackageDetailsModule)
    }
  },
  {
    path: 'cart',
    loadChildren: () => {
      return import('./modules/cart-details/cart-details.module').then(m => m.CartDetailsModule)
    }
  },
  {
    path: 'invoice',
    loadChildren: () => {
      return import('./modules/invoice/invoice.module').then(m => m.InvoiceModule)
    }
  },
  {
    path: 'user',
    canActivate: [AuthGuardService],
    loadChildren: () => {
      return import('./modules/user-details/user-details.module').then(m => m.UserDetailsModule)
    }
  },
  {
    path: 'samples',
    loadChildren: () => {
      return import('./modules/samples/samples.module').then(m => m.SamplesModule)
    }
  },
  {
    path: 'delete-account',
    loadChildren: () => import('./modules/delete-account/delete-account.module').then(m => m.DeleteAccountModule)
  },
  {
    path: 'resetpassword',
    canActivate: [ResetPasswordGuardService],
    loadChildren: () => {
      return import('./modules/reset-password/reset-password.module').then(m => m.ResetPasswordModule)
    }
  },
  {
    path: 'maintenance',
    component: MaintenancePageComponent,
    canActivate: [MaintenanceGuardService],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'maintenance'
      },
    ]
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
