/* NgRx */
import {createAction, props} from '@ngrx/store';
import {CalculateCartModel} from "../../models/calulate-cart.model";
import {GuestPurchaseFormModel} from "../../models/guest-purchase-form.model";
import {AddToCartModel} from "../../models/addToCart.model";
import {CartDataModel} from "../../models/cartData.model";

/******** CLEAR STATE ***********/
const CLEAR_STATE = '[cart details] clear state';
export const ClearState = createAction(CLEAR_STATE);

/******** CLEAR STATE RETAIN CART ***********/
const CLEAR_STATE_RETAIN_CART = '[cart details] clear state retain cart';
export const ClearStateRetainCart = createAction(CLEAR_STATE_RETAIN_CART);

/******** RESET SUCCESS STATE ***********/
const RESET_SUCCESS_STATE = '[cart details] reset success state';
export const ResetSuccessState = createAction(RESET_SUCCESS_STATE);

/******** CALCULATE CART ***********/
const CALCULATE_CART_REQUEST = '[cart details] calculate cart request';
export const CalculateCartRequest = createAction(CALCULATE_CART_REQUEST, props<{ payload: CalculateCartModel }>());
const CALCULATE_CART_SUCCESS = '[cart details] calculate cart success';
export const CalculateCartSuccess = createAction(CALCULATE_CART_SUCCESS, props<{ cartData: CartDataModel }>());
const CALCULATE_CART_FAILURE = '[cart details] calculate cart failure';
export const CalculateCartFailure = createAction(CALCULATE_CART_FAILURE, props<any>());

/******** ADD TO ITEMS ***********/
const ADD_TO_ITEMS = '[cart details] add to items requested';
export const AddToItems = createAction(ADD_TO_ITEMS, props<any>());

/******** RESET CART ***********/
const RESET_CART = '[cart details] reset cart';
export const ResetCart = createAction(RESET_CART);

/********** ENABLE GUEST PURCHASE FORM **************/
const ENABLE_GUEST_PURCHASE_FORM = '[cart details] enable guest purchase form';
export const EnableGuestPurchaseForm = createAction(ENABLE_GUEST_PURCHASE_FORM, props<any>());

/******** PLACE GUEST ORDER ***********/
const PLACE_GUEST_ORDER_REQUEST = '[cart details] place guest order requested';
export const PlaceGuestOrderRequest = createAction(PLACE_GUEST_ORDER_REQUEST, props<any>());

const PLACE_GUEST_ORDER_SUCCESS = '[cart details] place guest order success';
export const PlaceGuestOrderSuccess = createAction(PLACE_GUEST_ORDER_SUCCESS, props<any>());

const PLACE_GUEST_ORDER_FAILURE = '[cart details] place guest order failure';
export const PlaceGuestOrderFailure = createAction(PLACE_GUEST_ORDER_FAILURE, props<any>());

/******** SAVE GUEST PURCHASE FORM ***********/
const SAVE_GUEST_PURCHASE_FORM_REQUEST = '[cart details] save guest purchase form requested';
export const SaveGuestPurchaseForm = createAction(SAVE_GUEST_PURCHASE_FORM_REQUEST, props<{ payload: GuestPurchaseFormModel }>());

/******** FETCH CART ***********/
const FETCH_CART_REQUEST = '[cart details] fetch cart requested';
export const FetchCartRequest = createAction(FETCH_CART_REQUEST);

const FETCH_CART_SUCCESS = '[cart details] fetch cart success';
export const FetchCartSuccess = createAction(FETCH_CART_SUCCESS,
  props<{ cartData: CartDataModel }>());

const FETCH_CART_FAILURE = '[cart details] fetch cart failure';
export const FetchCartFailure = createAction(FETCH_CART_FAILURE, props<any>());

/******** ADD TO CART ***********/
const ADD_TO_CART_REQUEST = '[cart details] add to card requested';
export const AddToCartRequest = createAction(ADD_TO_CART_REQUEST, props<{ payload: AddToCartModel }>());

const ADD_TO_CART_SUCCESS = '[cart details] add to card success';
export const AddToCartSuccess = createAction(ADD_TO_CART_SUCCESS, props<any>());

const ADD_TO_CART_FAILURE = '[cart details] add to card failure';
export const AddToCartFailure = createAction(ADD_TO_CART_FAILURE, props<any>());

/******** FETCH CART COUNT ***********/
const FETCH_CART_COUNT_REQUEST = '[cart details] fetch cart count requested';
export const FetchCartCountRequest = createAction(FETCH_CART_COUNT_REQUEST, props<any>());

const FETCH_CART_COUNT_SUCCESS = '[cart details] fetch cart count success';
export const FetchCartCountSuccess = createAction(FETCH_CART_COUNT_SUCCESS, props<any>());

const FETCH_CART_COUNT_FAILURE = '[cart details] fetch cart count failure';
export const FetchCartCountFailure = createAction(FETCH_CART_COUNT_FAILURE, props<any>());

/******** QUANTITY_INCREMENT ***********/
const QUANTITY_INCREMENT_REQUEST = '[cart details] quantity increment requested';
export const QuantityIncrementRequest = createAction(QUANTITY_INCREMENT_REQUEST, props<any>());

const QUANTITY_INCREMENT_SUCCESS = '[cart details] quantity increment success';
export const QuantityIncrementSuccess = createAction(QUANTITY_INCREMENT_SUCCESS, props<any>());

const QUANTITY_INCREMENT_FAILURE = '[cart details] quantity increment failure';
export const QuantityIncrementFailure = createAction(QUANTITY_INCREMENT_FAILURE, props<any>());

/******** QUANTITY_DECREMENT ***********/
const QUANTITY_DECREMENT_REQUEST = '[cart details] quantity decrement requested';
export const QuantityDecrementRequest = createAction(QUANTITY_DECREMENT_REQUEST, props<any>());

const QUANTITY_DECREMENT_SUCCESS = '[cart details] quantity decrement success';
export const QuantityDecrementSuccess = createAction(QUANTITY_DECREMENT_SUCCESS, props<any>());

const QUANTITY_DECREMENT_FAILURE = '[cart details] quantity decrement failure';
export const QuantityDecrementFailure = createAction(QUANTITY_DECREMENT_FAILURE, props<any>());

/******** REMOVE ITEM ***********/
const REMOVE_ITEM_REQUEST = '[cart details] remove item requested';
export const RemoveItemRequest = createAction(REMOVE_ITEM_REQUEST, props<any>());

const REMOVE_ITEM_SUCCESS = '[cart details] remove item success';
export const RemoveItemSuccess = createAction(REMOVE_ITEM_SUCCESS, props<any>());

const REMOVE_ITEM_FAILURE = '[cart details] remove item failure';
export const RemoveItemFailure = createAction(REMOVE_ITEM_FAILURE, props<any>());

/******** FETCH ADDRESS LIST ***********/
const FETCH_ADDRESS_LIST_REQUEST = '[cart details] fetch address list requested';
export const FetchAddressListRequest = createAction(FETCH_ADDRESS_LIST_REQUEST, props<any>());

const FETCH_ADDRESS_LIST_SUCCESS = '[cart details] fetch address list successful';
export const FetchAddressListSuccess = createAction(FETCH_ADDRESS_LIST_SUCCESS, props<any>());

const FETCH_ADDRESS_LIST_FAILURE = '[cart details] fetch address list failure';
export const FetchAddressListFailure = createAction(FETCH_ADDRESS_LIST_FAILURE, props<any>());

/******** FETCH CARDS LIST ***********/
const USER_PAYMENT_CARDS_REQUEST = '[cart details] user payment cards requested';
export const UserPaymentCardsRequest = createAction(USER_PAYMENT_CARDS_REQUEST, props<any>());

const USER_PAYMENT_CARDS_SUCCESS = '[cart details] user payment cards successful';
export const UserPaymentCardsSuccess = createAction(USER_PAYMENT_CARDS_SUCCESS, props<any>());

const USER_PAYMENT_CARDS_FAILURE = '[cart details] user payment cards failure';
export const UserPaymentCardsFailure = createAction(USER_PAYMENT_CARDS_FAILURE, props<any>());

/******** SET SHIPPING ADDRESS ***********/
const SET_SHIPPING_ADDRESS_REQUEST = '[cart details] set shipping address requested';
export const SetShippingAddressRequest = createAction(SET_SHIPPING_ADDRESS_REQUEST, props<any>());

const SET_SHIPPING_ADDRESS_SUCCESS = '[cart details] set shipping address success';
export const SetShippingAddressSuccess = createAction(SET_SHIPPING_ADDRESS_SUCCESS, props<any>());

const SET_SHIPPING_ADDRESS_FAILURE = '[cart details] set shipping address failure';
export const SetShippingAddressFailure = createAction(SET_SHIPPING_ADDRESS_FAILURE, props<any>());

/******** UPDATE PAYMENT OPTION ***********/
const UPDATE_PAYMENT_OPTION_REQUEST = '[cart details] update payment option requested';
export const UpdatePaymentOptionRequest = createAction(UPDATE_PAYMENT_OPTION_REQUEST, props<any>());

const UPDATE_PAYMENT_OPTION_SUCCESS = '[cart details]  update payment option success';
export const UpdatePaymentOptionSuccess = createAction(UPDATE_PAYMENT_OPTION_SUCCESS, props<any>());

const UPDATE_PAYMENT_OPTION_FAILURE = '[cart details]  update payment option failure';
export const UpdatePaymentOptionFailure = createAction(UPDATE_PAYMENT_OPTION_FAILURE, props<any>());

/******** PLACE ORDER ***********/
const PLACE_ORDER_REQUEST = '[cart details] place order requested';
export const PlaceOrderRequest = createAction(PLACE_ORDER_REQUEST, props<any>());

const PLACE_ORDER_SUCCESS = '[cart details] place order success';
export const PlaceOrderSuccess = createAction(PLACE_ORDER_SUCCESS, props<{cartData: any; message: string; isActionRequired: boolean; clientSecret: string }>());

const PLACE_ORDER_FAILURE = '[cart details] place order failure';
export const PlaceOrderFailure = createAction(PLACE_ORDER_FAILURE, props<any>());

/***********CONFIRM PAYMENT  **************/
const CONFIRM_PAYMENT_REQUEST = '[cart details] confirm payment requested';
export const ConfirmPaymentRequest = createAction(CONFIRM_PAYMENT_REQUEST, props<{clientSecret: string }>());

const CONFIRM_PAYMENT_SUCCESS = '[cart details] confirm payment success';
export const ConfirmPaymentSuccess = createAction(CONFIRM_PAYMENT_SUCCESS,props<{ paymentIntent: any }>());

const CONFIRM_PAYMENT_FAILURE = '[cart details] confirm payment failure';
export const ConfirmPaymentFailure = createAction(CONFIRM_PAYMENT_FAILURE,props<{ message: string }>());

/******** CLEAR CART DATA ***********/
const CLEAR_CART_DATA = '[cart details] clear cart data';
export const ClearCartData = createAction(CLEAR_CART_DATA);

/******** CLEAR CART COUNT ***********/
const CLEAR_CART_COUNT = '[cart details] clear cart count';
export const ClearCartCount = createAction(CLEAR_CART_COUNT);

/******** CART SET ***********/
const CART_SET_REQUEST = '[cart details] cart set request';
export const CartSetRequest = createAction(CART_SET_REQUEST, props<any>());
const CART_SET_SUCCESS = '[cart details] cart set success';
export const CartSetSuccess = createAction(CART_SET_SUCCESS, props<any>());
const CART_SET_FAILURE = '[cart details] cart set failure';
export const CartSetFailure = createAction(CART_SET_FAILURE, props<any>());

