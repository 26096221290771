import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {UserDetailsRoutingModule} from "./user-details-routing.module";
import { UserDetailsWrapperComponent } from './user-details-wrapper/user-details-wrapper.component';
import { OrdersScreenComponent } from './orders/orders-screen/orders-screen.component';
import { SubscriptionsScreenComponent } from './subscriptions/subscriptions-screen/subscriptions-screen.component';
import {StoreModule} from "@ngrx/store";
import {reducer} from "./core/user-details.reducer";
import {EffectsModule} from "@ngrx/effects";
import {UserDetailsEffects} from "./core/user-details.effects";
import { AccountInformationComponent } from './personal-information/components/account-information/account-information.component';
import { AddressSectionComponent } from './personal-information/components/address-section/address-section.component';
import { PaymentOptionSectionComponent } from './personal-information/components/payment-option-section/payment-option-section.component';
import { AddressCardComponent } from './personal-information/components/address-card/address-card.component';
import { PaymentOptionCardComponent } from './personal-information/components/payment-option-card/payment-option-card.component';
import { AddPaymentOptionContainerComponent } from './personal-information/components/add-payment-option/add-payment-option-container/add-payment-option-container.component';
import { AddPaymentOptionFormComponent } from './personal-information/components/add-payment-option/add-payment-option-form/add-payment-option-form.component';
import { PersonalInformationContainerComponent } from './personal-information/personal-information-container/personal-information-container.component';
import { PersonalInformationScreenComponent } from './personal-information/personal-information-screen/personal-information-screen.component';
import { UserDetailsContainerComponent } from './user-details-wrapper/components/user-details-container/user-details-container.component';
import { UserDetailsScreenComponent } from './user-details-wrapper/components/user-details-screen/user-details-screen.component';
import { UserDetailsHeaderComponent } from './user-details-wrapper/components/user-details-header/user-details-header.component';
import {FlexLayoutModule} from "@angular/flex-layout";
import {SharedModule} from "../shared/shared.module";
import {AuthenticationModule} from "../authentication/authentication.module";
import { AddUpdateAddressContainerComponent } from './personal-information/components/add-update-address/add-update-address-container/add-update-address-container.component';
import { AddUpdateAddressFormComponent } from './personal-information/components/add-update-address/add-update-address-form/add-update-address-form.component';
import {ReactiveFormsModule} from "@angular/forms";
import { UpdateAccountInformationContainerComponent } from './personal-information/components/update-account-information/update-account-information-container/update-account-information-container.component';
import { UpdateAccountInformationFormComponent } from './personal-information/components/update-account-information/update-account-information-form/update-account-information-form.component';
import { DeleteAddressConfirmDialogComponent } from './personal-information/components/delete-address-confirm-dialog/delete-address-confirm-dialog.component';
import { DeletePaymentOptionConfirmDialogComponent } from './personal-information/components/delete-payment-option-confirm-dialog/delete-payment-option-confirm-dialog.component';
import { OrderCardComponent } from './orders/components/order-card/order-card.component';
import { SubscriptionsContainerComponent } from './subscriptions/subscriptions-container/subscriptions-container.component';
import { SubscriptionCardComponent } from './subscriptions/components/subscription-card/subscription-card.component';
import { SubscriptionDetailPopupContainerComponent } from './subscriptions/components/subscription-detail-popup/subscription-detail-popup-container/subscription-detail-popup-container.component';
import { SubscriptionDetailPopupScreenComponent } from './subscriptions/components/subscription-detail-popup/subscription-detail-popup-screen/subscription-detail-popup-screen.component';
import {CartDetailsModule} from "../cart-details/cart-details.module";
import { OrderDetailsPopupScreenComponent } from './orders/components/order-details-popup-screen/order-details-popup-screen.component';
import { AddressListLoaderComponent } from './personal-information/components/loader/address-list-loader/address-list-loader.component';
import { PaymentOptionListLoaderComponent } from './personal-information/components/loader/payment-option-list-loader/payment-option-list-loader.component';
import { AddressFormLoaderComponent } from './personal-information/components/loader/address-form-loader/address-form-loader.component';
import { AccountInformationFormLoaderComponent } from './personal-information/components/loader/account-information-form-loader/account-information-form-loader.component';
import { AccountInformationLoaderComponent } from './personal-information/components/loader/account-information-loader/account-information-loader.component';
import { OrdersListLoaderComponent } from './orders/components/loader/orders-list-loader/orders-list-loader.component';
import { OrderDetailsLoaderComponent } from './orders/components/loader/order-details-loader/order-details-loader.component';
import { OrderItemComponent } from './orders/components/order-item/order-item.component';
import { SubscriptionListLoaderComponent } from './subscriptions/components/loader/subscription-list-loader/subscription-list-loader.component';
import { SubscriptionCardLoaderComponent } from './subscriptions/components/loader/subscription-card-loader/subscription-card-loader.component';
import { SubscriptionDetailLoaderComponent } from './subscriptions/components/loader/subscription-detail-loader/subscription-detail-loader.component';
import {ShippingAddressSelectionCardComponent} from "../cart-details/authenticated-cart/components/shipping-address-selection/component/shipping-address-selection-card/shipping-address-selection-card.component";

@NgModule({
  declarations: [
    UserDetailsWrapperComponent,
    OrdersScreenComponent,
    SubscriptionsScreenComponent,
    AccountInformationComponent,
    AddressSectionComponent,
    PaymentOptionSectionComponent,
    AddressCardComponent,
    PaymentOptionCardComponent,
    AddPaymentOptionContainerComponent,
    AddPaymentOptionFormComponent,
    PersonalInformationContainerComponent,
    PersonalInformationScreenComponent,
    UserDetailsContainerComponent,
    UserDetailsScreenComponent,
    UserDetailsHeaderComponent,
    AddUpdateAddressContainerComponent,
    AddUpdateAddressFormComponent,
    UpdateAccountInformationContainerComponent,
    UpdateAccountInformationFormComponent,
    DeleteAddressConfirmDialogComponent,
    DeletePaymentOptionConfirmDialogComponent,
    SubscriptionsContainerComponent,
    SubscriptionCardComponent,
    SubscriptionDetailPopupContainerComponent,
    SubscriptionDetailPopupScreenComponent,
    OrderCardComponent,
    OrderDetailsPopupScreenComponent,
    AddressListLoaderComponent,
    PaymentOptionListLoaderComponent,
    AddressFormLoaderComponent,
    AccountInformationFormLoaderComponent,
    AccountInformationLoaderComponent,
    OrdersListLoaderComponent,
    OrderDetailsLoaderComponent,
    OrderItemComponent,
    SubscriptionListLoaderComponent,
    SubscriptionCardLoaderComponent,
    SubscriptionDetailLoaderComponent
  ],
  exports: [
    PaymentOptionCardComponent
  ],
  imports: [
    CommonModule,
    UserDetailsRoutingModule,
    FlexLayoutModule,
    SharedModule,
    AuthenticationModule,
    ReactiveFormsModule,
    StoreModule.forFeature('userDetails', reducer),
    EffectsModule.forFeature(
      [UserDetailsEffects]
    ),
    CartDetailsModule,
  ]
})
export class UserDetailsModule {}
